<template>
  <div>
    <v-card
      color="grey lighten-5"
      elevation="0"
      width="100%"
      class="pl-4 pb-4"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <span
        class="grey--text text--darken-4 subtitle-1 font-weight-medium mt-3"
      >
        {{ $t("overview") }}</span
      >
      <v-row>
        <v-col cols="6">
          <v-row no-gutters class="my-2">
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("county") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.county }}</v-col
            >
          </v-row>

          <v-row no-gutters class="my-2">
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("place") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.place }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("address") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{
                ticketDetails.street_address
              }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("cross1") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.cross1 }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-2" v-if="ticketDetails.cross2">
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("cross2") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.cross2 }}</v-col
            >
          </v-row>

          <v-row no-gutters class="my-2">
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("location") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.location }}</v-col
            >
          </v-row>

          <v-row
            no-gutters
            class="my-2"
            v-if="
              centerMap[this.ticketDetails.center_id] &&
              centerMap[this.ticketDetails.center_id].abbrev !== 'OUPS'
            "
          >
            <v-col class="body-2 grey--text text--darken-1"
              >{{ $t("expirationDate") }}:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{
                ticketDetails.expires
                  ? moment(ticketDetails.expires).format("DD MMM YYYY, hh:mm a")
                  : ""
              }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-2" v-else>
            <v-col class="body-2 grey--text text--darken-1"
              >Start by date:</v-col
            >
            <v-col
              cols="9"
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{
                ticketDetails.start_by
                  ? moment(ticketDetails.start_by).format(
                      "DD MMM YYYY, hh:mm a"
                    )
                  : ""
              }}</v-col
            >
          </v-row>

          <!-- <v-row>
                <v-col class="caption grey--text text--darken-1">{{
                  $t("WorkDate")
                }}</v-col>
                <v-col
                  class="grey--text text--darken-4 font-weight-medium body-2"
                  >{{
                    ticketDetails.work_date
                      ? moment(ticketDetails.work_date).format(
                          "DD MMM YYYY, hh:mm a"
                        )
                      : ""
                  }}</v-col
                >
              </v-row> -->
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" class="d-flex justify-end">
          <v-row
            no-gutters
            :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
          >
            <v-col>
              <v-row
                no-gutters
                :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 pb-2' : 'py-3'"
              >
                <v-col lg="8" md="8" xl="8">
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'JULIE'
                    "
                    class="pa-4"
                    height="150"
                    width="150"
                    style="border: 1px solid #e0e0e0; border-radius: 8%"
                    src="@/assets/images/julie-811-center.svg"
                    role="img"
                    alt="Julie 811 Center Logo"
                  />
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'OUPS'
                    "
                    class="pa-4"
                    height="150"
                    width="150"
                    style="border: 1px solid #e0e0e0; border-radius: 8%"
                    src="@/assets/images/ohio-811.svg"
                    role="img"
                    alt="Ohio 811 Center Logo"
                  />
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'UTAH'
                    "
                    class="pa-4"
                    height="150"
                    width="150"
                    style="border: 1px solid #e0e0e0; border-radius: 8%"
                    src="@/assets/images/utah-811.svg"
                    role="img"
                    alt="UTAH 811 Center Logo"
                  />
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'NCOCC'
                    "
                    class="py-4"
                    height="150"
                    width="150"
                    src="@/assets/images/NC811.svg"
                    role="img"
                    alt="NC 811 Center Logo"
                  />
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'UNCC'
                    "
                    class="pa-4"
                    height="150"
                    width="150"
                    style="border: 1px solid #e0e0e0; border-radius: 8%"
                    src="@/assets/images/CO811.svg"
                    role="img"
                    alt="Colorado 811 Center Logo"
                  />
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'VUPS'
                    "
                    class="pa-4"
                    height="150"
                    width="150"
                    style="border: 1px solid #e0e0e0; border-radius: 8%"
                    src="@/assets/images/VA811.svg"
                    role="img"
                    alt="VA 811 Center Logo"
                  />
                  <img
                    v-if="
                      centerMap[this.ticketDetails.center_id] &&
                      centerMap[this.ticketDetails.center_id].abbrev === 'USAS'
                    "
                    class="pa-4"
                    height="150"
                    width="150"
                    style="border: 1px solid #e0e0e0; border-radius: 8%"
                    src="@/assets/images/USAS-digalert-logo.svg"
                    role="img"
                    alt="USAS Digalert Logo"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      color="grey lighten-5"
      elevation="0"
      width="100%"
      class="pl-4 pb-4"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-list-item class="px-0" link @click="dialog = true">
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="grey--text text--darken-4 subtitle-1 font-weight-medium mt-3"
                >
                  {{ $t("overview") }}</span
                ></v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>{{ "mdi-chevron-right" }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-2">
        <v-col class="caption grey--text text--darken-1"
          >{{ $t("county") }}:</v-col
        >
        <v-col
          cols="10"
          class="grey--text text--darken-4 font-weight-medium body-2"
          >{{ ticketDetails.county }}</v-col
        >
      </v-row>

      <v-row no-gutters class="my-2">
        <v-col class="caption grey--text text--darken-1"
          >{{ $t("place") }}:</v-col
        >
        <v-col
          cols="10"
          class="grey--text text--darken-4 font-weight-medium body-2"
          >{{ ticketDetails.place }}</v-col
        >
      </v-row>
      <v-row no-gutters class="my-2">
        <v-col class="caption grey--text text--darken-1"
          >{{ $t("address") }}:</v-col
        >
        <v-col
          cols="10"
          class="grey--text text--darken-4 font-weight-medium body-2"
          >{{
            ticketDetails.street_address
          }}</v-col
        >
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" fullscreen v-if="$vuetify.breakpoint.smAndDown">
      <v-card color="grey lighten-5" elevation="0" width="100%" class="pl-4">
        <v-card-title class="pl-0 py-5">
          <v-btn plain @click="dialog = false"
            ><v-icon> mdi-arrow-left </v-icon></v-btn
          >
          <span class="grey--text text--darken-4 subtitle-1 font-weight-medium">
            {{ $t("overview") }}</span
          >
        </v-card-title>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-card-text class="pt-4 pl-3">
          <v-row no-gutters class="my-3">
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("county") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.county }}</v-col
            >
          </v-row>

          <v-row no-gutters class="my-3">
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("place") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.place }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("address") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{
                ticketDetails.street_address +
                " " +
                ticketDetails.place +
                " " +
                ticketDetails.county +
                " " +
                ticketDetails.state
              }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("cross1") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.cross1 }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-3" v-if="ticketDetails.cross2">
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("cross2") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.cross2 }}</v-col
            >
          </v-row>

          <v-row no-gutters class="my-3">
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("location") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{ ticketDetails.location }}</v-col
            >
          </v-row>

          <v-row
            no-gutters
            class="my-3"
            v-if="
              centerMap[this.ticketDetails.center_id] &&
              centerMap[this.ticketDetails.center_id].abbrev !== 'OUPS'
            "
          >
            <v-col class="caption grey--text text--darken-1"
              >{{ $t("expirationDate") }}:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{
                ticketDetails.expires
                  ? moment(ticketDetails.expires).format("DD MMM YYYY, hh:mm a")
                  : ""
              }}</v-col
            >
          </v-row>
          <v-row no-gutters class="my-3">
            <v-col class="caption grey--text text--darken-1"
              >Start by date:</v-col
            >
            <v-col
              class="grey--text text--darken-4 font-weight-medium body-2"
              >{{
                ticketDetails.expires
                  ? moment(ticketDetails.start_by).format(
                      "DD MMM YYYY, hh:mm a"
                    )
                  : ""
              }}</v-col
            >
          </v-row>

          <!-- <v-row>
           <v-col class="caption grey--text text--darken-1">{{
             $t("WorkDate")
           }}</v-col>
           <v-col
             class="grey--text text--darken-4 font-weight-medium body-2"
             >{{
               ticketDetails.work_date
                 ? moment(ticketDetails.work_date).format(
                     "DD MMM YYYY, hh:mm a"
                   )
                 : ""
             }}</v-col
           >
         </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    companyInfo() {
      return this.$store.state.Company.company;
    },
    centerMap() {
      return this.$store.getters.centerMap;
    },
  },
  data: () => ({
    dialog: false,
  }),
  async created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss">
.v-chip.v-size--large {
  border-radius: 27px;
  font-size: 16px;
  height: 45px;
}
</style>
