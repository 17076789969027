var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-card",
            {
              staticClass: "pl-4 pb-4",
              attrs: { color: "grey lighten-5", elevation: "0", width: "100%" },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "grey--text text--darken-4 subtitle-1 font-weight-medium mt-3",
                },
                [_vm._v(" " + _vm._s(_vm.$t("overview")))]
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "body-2 grey--text text--darken-1" },
                            [_vm._v(_vm._s(_vm.$t("county")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                              attrs: { cols: "9" },
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.county))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "body-2 grey--text text--darken-1" },
                            [_vm._v(_vm._s(_vm.$t("place")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                              attrs: { cols: "9" },
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.place))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "body-2 grey--text text--darken-1" },
                            [_vm._v(_vm._s(_vm.$t("address")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                              attrs: { cols: "9" },
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.street_address))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "body-2 grey--text text--darken-1" },
                            [_vm._v(_vm._s(_vm.$t("cross1")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                              attrs: { cols: "9" },
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.cross1))]
                          ),
                        ],
                        1
                      ),
                      _vm.ticketDetails.cross2
                        ? _c(
                            "v-row",
                            {
                              staticClass: "my-2",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "body-2 grey--text text--darken-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("cross2")) + ":")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-4 font-weight-medium body-2",
                                  attrs: { cols: "9" },
                                },
                                [_vm._v(_vm._s(_vm.ticketDetails.cross2))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "body-2 grey--text text--darken-1" },
                            [_vm._v(_vm._s(_vm.$t("location")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                              attrs: { cols: "9" },
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.location))]
                          ),
                        ],
                        1
                      ),
                      _vm.centerMap[this.ticketDetails.center_id] &&
                      _vm.centerMap[this.ticketDetails.center_id].abbrev !==
                        "OUPS"
                        ? _c(
                            "v-row",
                            {
                              staticClass: "my-2",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "body-2 grey--text text--darken-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("expirationDate")) + ":")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-4 font-weight-medium body-2",
                                  attrs: { cols: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ticketDetails.expires
                                        ? _vm
                                            .moment(_vm.ticketDetails.expires)
                                            .format("DD MMM YYYY, hh:mm a")
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            {
                              staticClass: "my-2",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "body-2 grey--text text--darken-1",
                                },
                                [_vm._v("Start by date:")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-4 font-weight-medium body-2",
                                  attrs: { cols: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ticketDetails.start_by
                                        ? _vm
                                            .moment(_vm.ticketDetails.start_by)
                                            .format("DD MMM YYYY, hh:mm a")
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-row",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "pl-8 pt-2"
                            : "mt-1 mx-5",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                {
                                  class: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "pt-5 pb-2"
                                    : "py-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { lg: "8", md: "8", xl: "8" } },
                                    [
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "JULIE"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/julie-811-center.svg"),
                                              role: "img",
                                              alt: "Julie 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "OUPS"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/ohio-811.svg"),
                                              role: "img",
                                              alt: "Ohio 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "UTAH"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/utah-811.svg"),
                                              role: "img",
                                              alt: "UTAH 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "NCOCC"
                                        ? _c("img", {
                                            staticClass: "py-4",
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/NC811.svg"),
                                              role: "img",
                                              alt: "NC 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "UNCC"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/CO811.svg"),
                                              role: "img",
                                              alt: "Colorado 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "VUPS"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/VA811.svg"),
                                              role: "img",
                                              alt: "VA 811 Center Logo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ] &&
                                      _vm.centerMap[
                                        this.ticketDetails.center_id
                                      ].abbrev === "USAS"
                                        ? _c("img", {
                                            staticClass: "pa-4",
                                            staticStyle: {
                                              border: "1px solid #e0e0e0",
                                              "border-radius": "8%",
                                            },
                                            attrs: {
                                              height: "150",
                                              width: "150",
                                              src: require("@/assets/images/USAS-digalert-logo.svg"),
                                              role: "img",
                                              alt: "USAS Digalert Logo",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-card",
            {
              staticClass: "pl-4 pb-4",
              attrs: { color: "grey lighten-5", elevation: "0", width: "100%" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "px-0",
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text text--darken-4 subtitle-1 font-weight-medium mt-3",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("overview")))]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s("mdi-chevron-right")),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("county")) + ":")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                      attrs: { cols: "10" },
                    },
                    [_vm._v(_vm._s(_vm.ticketDetails.county))]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("place")) + ":")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                      attrs: { cols: "10" },
                    },
                    [_vm._v(_vm._s(_vm.ticketDetails.place))]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("address")) + ":")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                      attrs: { cols: "10" },
                    },
                    [_vm._v(_vm._s(_vm.ticketDetails.street_address))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pl-4",
                  attrs: {
                    color: "grey lighten-5",
                    elevation: "0",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pl-0 py-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" mdi-arrow-left ")])],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "grey--text text--darken-4 subtitle-1 font-weight-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("overview")))]
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-divider")], 1),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-4 pl-3" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("county")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.county))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("place")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.place))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("address")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ticketDetails.street_address +
                                    " " +
                                    _vm.ticketDetails.place +
                                    " " +
                                    _vm.ticketDetails.county +
                                    " " +
                                    _vm.ticketDetails.state
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("cross1")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.cross1))]
                          ),
                        ],
                        1
                      ),
                      _vm.ticketDetails.cross2
                        ? _c(
                            "v-row",
                            {
                              staticClass: "my-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("cross2")) + ":")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-4 font-weight-medium body-2",
                                },
                                [_vm._v(_vm._s(_vm.ticketDetails.cross2))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "my-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("location")) + ":")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                            },
                            [_vm._v(_vm._s(_vm.ticketDetails.location))]
                          ),
                        ],
                        1
                      ),
                      _vm.centerMap[this.ticketDetails.center_id] &&
                      _vm.centerMap[this.ticketDetails.center_id].abbrev !==
                        "OUPS"
                        ? _c(
                            "v-row",
                            {
                              staticClass: "my-3",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("expirationDate")) + ":")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-4 font-weight-medium body-2",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ticketDetails.expires
                                        ? _vm
                                            .moment(_vm.ticketDetails.expires)
                                            .format("DD MMM YYYY, hh:mm a")
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "my-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1",
                            },
                            [_vm._v("Start by date:")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "grey--text text--darken-4 font-weight-medium body-2",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ticketDetails.expires
                                    ? _vm
                                        .moment(_vm.ticketDetails.start_by)
                                        .format("DD MMM YYYY, hh:mm a")
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }